import { ProgressIndicator } from '@popety_io/popety-io-lib'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import RouteGuard from './RouteGuard'
import { navigations } from './navigations'

export type RouteProps = {
  /**
   * Indicates whether or not this route requires admin role
   */
  private?: boolean
  /**
   * Indicates the name of the url
   */
  path: string
  /**
   * Indicates the component of the page
   */
  page: any
  /**
   * Indicates whether or not this route requires pga role
   */
  pga?: boolean
}

const AppRoutes = () => (
  <Suspense fallback={<ProgressIndicator circular anchor="window" />}>
    <Routes>
      {navigations.map((nav: RouteProps) => (
        <Route
          key={nav.path}
          element={<RouteGuard admin={nav.private} pga={nav.pga} />}
        >
          <Route path={nav.path} element={nav.page} />
        </Route>
      ))}
    </Routes>
  </Suspense>
)

export default AppRoutes
