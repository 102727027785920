import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'

import { isProd } from '../config'
import rootReducer, { RootState } from './reducers'

// eslint-disable-next-line global-require
// const logMiddleware = [!isProd && require('redux-logger').default].filter(
//   Boolean
// )

const store = configureStore({
  reducer: rootReducer,
  devTools: !isProd,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}),
})

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
