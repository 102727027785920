// TODO: ADD USER WHEN CREATED ON ARRAY

const updateUsersById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { data } = action.payload || {}
  const { input } = action.meta.arg

  if (data) {
    state.usersById[input.id] = {
      ...state.usersById[input.id],
      ...input,
    }
  }
}

const addUserById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { data } = action.payload || {}
  const { input } = action.meta.arg

  if (data) {
    const { id } = data

    state.usersById[id] = {
      ...input,
      ...data,
      status: 'active',
    }
  }
}

const updateOfficesById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { data } = action.payload || {}
  const input = action.meta.arg

  if (data) {
    state.officesById[input.id] = { ...state.officesById[input.id], ...data }
  }
}

export { updateUsersById, updateOfficesById, addUserById }
