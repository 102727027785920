import { createSelector } from '@reduxjs/toolkit'

const locationsSelector = (state) => state.locations

const citiesSelector = (state) => state.locations.cities

const landUsePlansSelector = (state) => state.locations.landUsePlans

const assignementsSelector = (state) => state.locations.assignements

const assignementDetailsSelector = (state) => state.locations.assignementDetails

const assignementLandUsePlansSelector = createSelector(
  landUsePlansSelector,
  (landUsePlansSelector) =>
    landUsePlansSelector?.map((lup) => ({
      ...lup,
      label: `${lup?.plan_type} - ${lup?.name}`,
    })),
)

const assignementDetailsGeojonSelector = createSelector(
  assignementDetailsSelector,
  (assignementDetails) =>
    assignementDetails?.geojson_polygon
      ? JSON.parse(assignementDetails?.geojson_polygon)
      : null,
)

const assignementDetailsGeoCenter = createSelector(
  assignementDetailsSelector,
  (assignementDetails) => [
    assignementDetails?.geocenter_lat,
    assignementDetails?.geocenter_lon,
  ],
)

const loadGeojsonSelector = (state) => state.locations.loadGeojson

const documentsSelector = (state) => state.locations.documents

const typeSelector = (state) => state.locations.type

const documentsTypeSelector = (state) => state.locations.documentsType

const statusSelector = (state) => state.locations.status

const lupDetailsSelector = (state) => state.locations.lupDetails

const historySelector = (state) => state.locations.history

const landUsePlanCopySelector = (state) => state.locations.landUsePlanCopy

const assignementLandUsePlanCopySelector = createSelector(
  landUsePlanCopySelector,
  (landUsePlanCopySelector) =>
    landUsePlanCopySelector?.map((lup) => ({
      ...lup,
      label: `${lup?.plan_type} - ${lup?.name}`,
    })),
)

const assignementsCopySelector = (state) => state.locations.assignementsCopy

const assignementCopySelector = (state) => state.locations.assignementCopyObject

export {
  citiesSelector,
  locationsSelector,
  assignementsSelector,
  assignementDetailsSelector,
  assignementLandUsePlansSelector,
  assignementDetailsGeojonSelector,
  assignementDetailsGeoCenter,
  assignementLandUsePlanCopySelector,
  documentsSelector,
  typeSelector,
  documentsTypeSelector,
  statusSelector,
  lupDetailsSelector,
  historySelector,
  assignementsCopySelector,
  assignementCopySelector,
  loadGeojsonSelector,
}
