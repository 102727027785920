const clearAppDataAndCache = () => {
  // Clear app data
  localStorage.clear()
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
  }
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
  const { indexedDB } = window

  if (indexedDB) {
    const request = indexedDB.deleteDatabase('your-database-name')

    request.onsuccess = () => {
      console.log('IndexedDB database deleted successfully')
    }
    request.onerror = () => {
      console.error('Failed to delete IndexedDB database')
    }
  }
  sessionStorage.clear()

  // Clear cache and reload app
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName)
    })
  })
  window.location.reload()
}

export { clearAppDataAndCache }
