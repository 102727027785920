/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import locationsFutureServices from './locationsFutureServices'

const initialState = {
  loading: false,
  futureLandUsePlansCopy: [],
  futureDocuments: [],
  futureHistory: [],
  futureLandUsePlans: [],
  futureAssignements: [],
  futureAssignementDetails: {},
  futureType: [],
  futureLupDetails: {},
  futureAssignementsCopy: [],
  futureAssignementCopyObject: {},
}

export const getAllLandUsePlansFuture = createAsyncThunk(
  'locationsServices/getAllLandUsePlansFuture',
  locationsFutureServices.getAllLandUsePlansFuture,
)

export const getAllAssignementsFuture = createAsyncThunk(
  'locationsFutureServices/getAllAssignementsFuture',
  locationsFutureServices.getAllAssignementsFuture,
)

export const getFutureAssignementDetails = createAsyncThunk(
  'locationsFutureServices/getFutureAssignementDetails',
  locationsFutureServices.getFutureAssignementDetails,
)

export const getFutureLandUsePlansDocuments = createAsyncThunk(
  'locationsFutureServices/getFutureLandUsePlansDocuments',
  locationsFutureServices.getFutureLandUsePlansDocuments,
)

export const getFutureLandUsePlanDetails = createAsyncThunk(
  'locationsFutureServices/getFutureLandUsePlanDetails',
  locationsFutureServices.getFutureLandUsePlanDetails,
)

export const addLandUsePlanFuture = createAsyncThunk(
  'locationsFutureServices/addLandUsePlanFuture',
  locationsFutureServices.addLandUsePlanFuture,
)

export const addAssignementsFuture = createAsyncThunk(
  'locationsFutureServices/addAssignementsFuture',
  locationsFutureServices.addAssignementsFuture,
)

export const updateFutureLandUsePlan = createAsyncThunk(
  'locationsFutureServices/updateFutureLandUsePlan',
  locationsFutureServices.updateFutureLandUsePlan,
)

export const deleteFutureLandUsePlan = createAsyncThunk(
  'locationsFutureServices/deleteFutureLandUsePlan',
  locationsFutureServices.deleteFutureLandUsePlan,
)

export const updateFutureLandUsePlanDocument = createAsyncThunk(
  'locationsFutureServices/updateFutureLandUsePlanDocument',
  locationsFutureServices.updateFutureLandUsePlanDocument,
)

export const addFutureLandUsePlanDocument = createAsyncThunk(
  'locationsFutureServices/addFutureLandUsePlanDocument',
  locationsFutureServices.addFutureLandUsePlanDocument,
)

export const deleteFutureLandUsePlanDocument = createAsyncThunk(
  'locationsFutureServices/deleteFutureLandUsePlanDocument',
  locationsFutureServices.deleteFutureLandUsePlanDocument,
)

export const updateFutureLandUsePlanAssignement = createAsyncThunk(
  'locationsFutureServices/updateFutureLandUsePlanAssignement',
  locationsFutureServices.updateFutureLandUsePlanAssignement,
)

export const getFutureLandUseplansCopy = createAsyncThunk(
  'locationsFutureServices/getFutureLandUseplansFutureCopy',
  locationsFutureServices.getAllLandUsePlansFuture,
)

export const getFutureAssignementsCopy = createAsyncThunk(
  'locationsFutureServices/getFutureAssignementsCopy',
  locationsFutureServices.getAllAssignementsFuture,
)

export const locationsFuture = createSlice({
  name: 'locationsFuture',
  initialState,
  reducers: {
    resetFutureLupSideData: (state) => {
      state.futureDocuments = []
      state.futureLupDetails = {}
    },
    resetFutureAssignementDetails: (state) => {
      state.futureAssignementDetails = {}
    },
    setCopyFutureAssignement: (state, action) => {
      const { payload } = action

      state.futureAssignementCopyObject = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLandUsePlansFuture.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.futureLandUsePlans = data
    })
    builder.addCase(getAllAssignementsFuture.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.futureAssignements = data
    })
    builder.addCase(getFutureAssignementsCopy.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.futureAssignementsCopy = data
    })
    builder.addCase(getFutureAssignementDetails.fulfilled, (state, action) => {
      const { data = {} } = action.payload || {}

      state.futureAssignementDetails = data?.[0]
    })
    builder.addCase(
      getFutureLandUsePlansDocuments.fulfilled,
      (state, action) => {
        const { data = {} } = action.payload || {}

        state.futureDocuments = data
      },
    )
    builder.addCase(getFutureLandUseplansCopy.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.futureLandUsePlansCopy = data
    })
    builder.addCase(getFutureLandUsePlanDetails.fulfilled, (state, action) => {
      const { data = {} } = action.payload || {}

      state.futureLupDetails = data?.[0]
    })
    builder.addCase(addLandUsePlanFuture.fulfilled, (state, action) => {
      const data = action.payload || {}

      state.futureLandUsePlans = [...state.futureLandUsePlans, data]
    })
    builder.addCase(addAssignementsFuture.fulfilled, (state, action) => {
      const data = action.payload || {}

      state.futureAssignements = [...state.futureAssignements, data]
    })
    builder.addCase(updateFutureLandUsePlan.fulfilled, (state, action) => {
      const { arg = {} } = action.meta || []

      state.futureLupDetails = arg
    })
    builder.addCase(deleteFutureLandUsePlan.fulfilled, (state, action) => {
      const { arg = {} } = action.meta || []

      if (arg.id) {
        state.futureLandUsePlans = state.futureLandUsePlans.filter((item) => {
          return item.id !== arg.id
        })
      }
    })
    builder.addCase(
      updateFutureLandUsePlanDocument.fulfilled,
      (state, action) => {
        const { arg = {} } = action.meta || []

        state.futureDocuments = state.futureDocuments.map((item) => {
          if (item.id === arg.id) {
            return arg
          }

          return item
        })
      },
    )
    builder.addCase(addFutureLandUsePlanDocument.fulfilled, (state, action) => {
      const data = action.payload

      if (data) {
        state.futureDocuments = [
          ...state.futureDocuments,
          {
            id: data.id,
            name: '',
            url: '',
            land_use_plan_documents_type_id: 1,
          },
        ]
      }
    })
    builder.addCase(
      deleteFutureLandUsePlanDocument.fulfilled,
      (state, action) => {
        const { arg = {} } = action.meta || []

        if (arg.documentId) {
          state.futureDocuments = state.futureDocuments.filter(
            (item) => item.id !== arg.documentId,
          )
        }
      },
    )
    builder.addCase(
      updateFutureLandUsePlanAssignement.fulfilled,
      (state, action) => {
        const { data } = action.payload || {}

        state.futureAssignementDetails = data
      },
    )
  },
})

export const {
  resetFutureAssignementDetails,
  resetFutureLupSideData,
  setCopyFutureAssignement,
} = locationsFuture.actions

export const locationsFutureReducer = {
  locationsFuture: locationsFuture.reducer,
}
