import { createSelector } from '@reduxjs/toolkit'

const officesStateSelector = (state) => state.offices

const officesByIdSelector = (state) => state.offices.officesById

const officesSelector = createSelector(officesByIdSelector, (offices) =>
  Object.values(offices)?.filter((val) => val?.id),
)

const officesLoadingSelector = createSelector(
  officesStateSelector,
  (offices) => offices.loading,
)

export { officesByIdSelector, officesSelector, officesLoadingSelector }
