import { createSelector } from '@reduxjs/toolkit'

const usersStateSelector = (state) => state.users

const loginHistorySelector = (state) => state.loginHistory?.loginHistory

const loginHistoryLoadingSelector = createSelector(
  usersStateSelector,
  (users) => users?.loading,
)

const countLoginHistorySelector = createSelector(
  usersStateSelector,
  (users) => users.count,
)

export {
  loginHistorySelector,
  loginHistoryLoadingSelector,
  countLoginHistorySelector,
}
