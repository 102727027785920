/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import locationsServices from './locationsServices'

const initialState = {
  loading: false,
  loadGeojson: false,
  cities: [],
  landUsePlans: [],
  landUsePlansCopy: [],
  documents: [],
  futureDocuments: [],
  history: [],
  futureHistory: [],
  futureLandUsePlans: [],
  assignements: [],
  assignementDetails: {},
  type: [],
  documentsType: [],
  status: [],
  lupDetails: {},
  assignementsCopy: [],
  assignementCopyObject: {},
}

export const getAllCities = createAsyncThunk(
  'locationsServices/getAllCities',
  locationsServices.getAllCities,
)

export const getAllLandUsePlans = createAsyncThunk(
  'locationsServices/getAllLandUsePlans',
  locationsServices.getAllLandUsePlans,
)

export const getAllLandUsePlansCopy = createAsyncThunk(
  'locationsServices/getAllLandUsePlansCopy',
  locationsServices.getAllLandUsePlans,
)

export const getAllAssignements = createAsyncThunk(
  'locationsServices/getAllAssignements',
  locationsServices.getAllAssignements,
)

export const getAllAssignementsCopy = createAsyncThunk(
  'locationsServices/getAllAssignementsCopy',
  locationsServices.getAllAssignements,
)

export const getAssignementDetails = createAsyncThunk(
  'locationsServices/getAssignementDetails',
  locationsServices.getAssignementDetails,
)

export const getLandUsePlansDocuments = createAsyncThunk(
  'locationsServices/getLandUsePlansDocuments',
  locationsServices.getLandUsePlansDocuments,
)

export const getSideDataBackAdmin = createAsyncThunk(
  'locationsServices/getSideDataBackAdmin',
  locationsServices.getSideDataBackAdmin,
)

export const getLandUsePlanDetails = createAsyncThunk(
  'locationsServices/getLandUsePlanDetails',
  locationsServices.getLandUsePlanDetails,
)

export const getHistoryBydId = createAsyncThunk(
  'locationsServices/getHistoryBydId',
  locationsServices.getHistoryBydId,
)

export const updateLandUsePlan = createAsyncThunk(
  'locationsServices/updateLandUsePlan',
  locationsServices.updateLandUsePlan,
)

export const updateLandUsePlanAssignement = createAsyncThunk(
  'locationsServices/updateLandUsePlanAssignement',
  locationsServices.updateLandUsePlanAssignement,
)

export const addLandUsePlanHistory = createAsyncThunk(
  'locationsServices/addLandUsePlanHistory',
  locationsServices.addLandUsePlanHistory,
)

export const updateLandUsePlanHistory = createAsyncThunk(
  'locationsServices/updateLandUsePlanHistory',
  locationsServices.updateLandUsePlanHistory,
)

export const deleteLandUsePlanHistory = createAsyncThunk(
  'locationsServices/deleteLandUsePlanHistory',
  locationsServices.deleteLandUsePlanHistory,
)

export const updateLandUsePlanDocument = createAsyncThunk(
  'locationsServices/updateLandUsePlanDocument',
  locationsServices.updateLandUsePlanDocument,
)

export const addLandUsePlanDocument = createAsyncThunk(
  'locationsServices/addLandUsePlanDocument',
  locationsServices.addLandUsePlanDocument,
)

export const deleteLandUsePlanDocument = createAsyncThunk(
  'locationsServices/deleteLandUsePlanDocument',
  locationsServices.deleteLandUsePlanDocument,
)

export const locations = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetLupSideData: (state) => {
      state.documents = []
      state.history = []
      state.lupDetails = {}
    },
    resetAssignementDetails: (state) => {
      state.assignementDetails = {}
    },
    setCopyAssignement: (state, action) => {
      const { payload } = action

      state.assignementCopyObject = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCities.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.cities = data
    })
    builder.addCase(getAllLandUsePlans.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.landUsePlans = data
    })
    builder.addCase(getAllAssignements.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.assignements = data
    })
    builder.addCase(getAssignementDetails.pending, (state) => {
      state.loadGeojson = true
    })

    builder.addCase(getAssignementDetails.rejected, (state) => {
      state.loadGeojson = false
    })
    builder.addCase(getAssignementDetails.fulfilled, (state, action) => {
      const { data = {} } = action.payload || {}

      state.loadGeojson = false

      state.assignementDetails = data?.[0]
    })
    builder.addCase(getLandUsePlansDocuments.fulfilled, (state, action) => {
      const { data = {} } = action.payload || {}

      state.documents = data
    })
    builder.addCase(getSideDataBackAdmin.fulfilled, (state, action) => {
      const { data = {} } = action.payload || {}

      state.status = data?.status
      state.type = data?.type
      state.documentsType = data?.documentsType
    })
    builder.addCase(getLandUsePlanDetails.fulfilled, (state, action) => {
      const { data = {} } = action.payload || {}

      state.lupDetails = data?.[0]
    })
    builder.addCase(getHistoryBydId.fulfilled, (state, action) => {
      const { data = {} } = action.payload || {}

      state.history = data
    })
    builder.addCase(updateLandUsePlan.fulfilled, (state, action) => {
      const { data } = action.payload || {}

      state.lupDetails = data
    })
    builder.addCase(updateLandUsePlanAssignement.fulfilled, (state, action) => {
      const { data } = action.payload || {}

      state.assignementDetails = data
    })
    builder.addCase(getAllLandUsePlansCopy.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.landUsePlanCopy = data
    })
    builder.addCase(getAllAssignementsCopy.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.assignementsCopy = data
    })
    builder.addCase(addLandUsePlanHistory.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      if (data) {
        state.history = [
          ...state.history,
          { id: data.id, description: '', official_date: '' },
        ]
      }
    })
    builder.addCase(updateLandUsePlanHistory.fulfilled, (state, action) => {
      const { arg = {} } = action.meta || []

      if (arg.historyId) {
        state.history = state.history.map((item) => {
          if (item.id === arg.historyId) {
            return {
              ...arg.history,
            }
          }

          return item
        })
      }
    })
    builder.addCase(deleteLandUsePlanHistory.fulfilled, (state, action) => {
      const { arg = {} } = action.meta || []

      if (arg.historyId) {
        state.history = state.history.filter(
          (item) => item.id !== arg.historyId,
        )
      }
    })
    builder.addCase(addLandUsePlanDocument.fulfilled, (state, action) => {
      const data = action.payload

      if (data) {
        state.documents = [
          ...state.documents,
          {
            id: data.id,
            name: '',
            url: '',
            land_use_plan_documents_type_id: 1,
          },
        ]
      }
    })
    builder.addCase(deleteLandUsePlanDocument.fulfilled, (state, action) => {
      const { arg = {} } = action.meta || []

      if (arg.documentId) {
        state.documents = state.documents.filter(
          (item) => item.id !== arg.documentId,
        )
      }
    })
    builder.addCase(updateLandUsePlanDocument.fulfilled, (state, action) => {
      const { arg = {} } = action.meta || {}
      const { data = '' } = action.payload || ''

      if (data) {
        state.documents = state.documents.map((item) => {
          if (item.id === arg?.document?.id) {
            return {
              ...arg.document,
              url: data,
            }
          }

          return item
        })
      }
    })
  },
})

export const { resetAssignementDetails, resetLupSideData, setCopyAssignement } =
  locations.actions

export const locationsReducer = {
  locations: locations.reducer,
}
