import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export const CompaniesSearchRoot = styled(Paper)`
  width: 100%;

  > .Title {
    flex-grow: 1;
    padding: 16px 24px;
  }

  .MuiTextField-root {
    width: 100%;
  }
`

export const ParcelBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px;
`
