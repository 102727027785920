import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {
  Button,
  fetchOnce,
  mapBy,
  PageTitle,
  useI18n,
} from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import capitalize from '@mui/material/utils/capitalize'

import { getSupports, supportsSelector, updateSupports } from './redux'

const locales = [
  { id: 1, label: 'French', value: 'fr' },
  { id: 2, label: 'English', value: 'en' },
  { id: 3, label: 'German', value: 'de' },
  { id: 4, label: 'Italian', value: 'it' },
]

const UpdateContacts = () => {
  const dispatch = useDispatch<any>()
  const supports = useSelector(supportsSelector)
  const { t } = useI18n()

  const [locale, setLocale] = useState('fr')

  useEffect(() => {
    fetchOnce('support', '', () => dispatch(getSupports()))
  }, [])

  const [contacts, setContacts] = useState<Record<string, any>>({})

  const contactList = Object.values(contacts).sort((a, b) => +a.id - +b.id)

  const handleSave = () => {
    dispatch(updateSupports(contactList))
  }

  const handleChange = (item: any) => (e: any) => {
    const { value } = e.target

    setContacts({
      ...contacts,
      [item.name]: {
        ...contacts[item.name],
        value: { ...contacts[item.name]?.value, [locale]: value },
      },
    })
  }

  useEffect(() => {
    if (supports) {
      setContacts(mapBy('name', supports))
    }
  }, [supports])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      p={3}
      alignItems="flex-start"
    >
      <PageTitle>{t('common.support')}</PageTitle>

      <ToggleButtonGroup value={locale} exclusive size="small">
        {locales.map((item) => (
          <ToggleButton
            key={item.id}
            value={item.value}
            onClick={() => setLocale(item.value)}
          >
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <Grid container spacing={3}>
        {contactList.map((item) => (
          <Grid key={item?.id} item xs={12} md={7}>
            <TextField
              label={capitalize(t(`common.${item.name}`))}
              value={item.value[locale]}
              onChange={handleChange(item)}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ maxWidth: 400 }}
            />
          </Grid>
        ))}
      </Grid>

      <Button onClick={handleSave} variant="contained" color="primary">
        {t('common.save')}
      </Button>
    </Box>
  )
}

export default UpdateContacts
