import { memo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { authRolesSelector } from '../pages/Login'

export type RouteGuardProps = {
  /**
   * Indicates whether or not this route requires admin role
   */
  admin?: boolean
  /**
   * Indicates whether or not this route requires pga role
   */
  pga?: boolean
}

/**
 * App route guard
 */
const RouteGuard = ({ admin, pga }: RouteGuardProps) => {
  const location = useLocation()
  const state = { from: location.pathname }

  const roles = useSelector(authRolesSelector)
  const superAdmin = roles.includes('Admin')
  const login = !!roles.length
  const requireAdmin = admin && !roles.includes('Back_popety')

  if (superAdmin || (!admin && !pga)) return <Outlet />

  if (!login) {
    return <Navigate to="/login" state={state} replace />
  }
  if (requireAdmin && roles.includes('Back_pga')) {
    return <Navigate to="/pga" state={state} replace />
  }
  if (requireAdmin) {
    return <Navigate to="/" state={state} replace />
  }

  return <Outlet />
}

export default memo(RouteGuard)
