import { memo, Suspense } from 'react'
import { Routes } from 'react-router-dom'

const HeaderActions = () => (
  <Suspense>
    <Routes />
  </Suspense>
)

export default memo(HeaderActions)
