import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider, ThemeProvider } from '@popety_io/popety-io-lib'

import { PAGES } from './config'
import Routes from './navigation'
import { store } from './redux'
import Layout from './components/Layout'

import './styles/GlobalStyle.css'

const App = () => (
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <I18nProvider appPages={PAGES}>
          <Layout>
            <Routes />
          </Layout>
        </I18nProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
)

export default App
