import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import loginHistoryService from './loginHistoryService'

const initialState = {
  loading: false,
  loginHistory: [],
}

export const getLoginHistoryByCompany = createAsyncThunk(
  'loginHistory/getLoginHistoryByCompany',
  loginHistoryService.getLoginHistoryByCompany,
)

export const loginHistory = createSlice({
  name: 'loginHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoginHistoryByCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(getLoginHistoryByCompany.rejected, (state) => {
        state.loading = false
      })
      .addCase(getLoginHistoryByCompany.fulfilled, (state, action) => {
        state.loading = false
        const { data = {} } = action.payload || {}

        state.loginHistory = data
      })
  },
})

export const loginHistoryReducer = {
  loginHistory: loginHistory.reducer,
}
