import { ApiClient } from '../../../../services'
// TODO: Fix this issue
// eslint-disable-next-line import/no-cycle

const client = new ApiClient()

const getAllLandUsePlansFuture = async (cityId: string) => {
  const data = await client.get(`/cities/${cityId}/futurelanduseplans`)

  return data
}

// const getAllAssignements = async (lupId: string) => {
//   const data = await client.get(`/cities/${lupId}/assignements`)

//   return data
// }

// const getAssignementDetails = async (lupaId: string) => {
//   const data = await client.get(`/cities/${lupaId}/assignements/details`)

//   return data
// }

// const getLandUsePlansDocuments = async (lupId: string) => {
//   const data = await client.get(`/cities/${lupId}/landUsePlan/documents`)

//   return data
// }

// const getSideDataBackAdmin = async () => {
//   const data = await client.get(`/cities/landUsePlan/data`)

//   return data
// }

// const getLandUsePlanDetails = async (lupId: string) => {
//   const data = await client.get(`/cities/${lupId}/landUsePlan/details`)

//   return data
// }

// const getHistoryBydId = async (lupId: string) => {
//   const data = await client.get(`/cities/${lupId}/history`)

//   return data
// }

// const updateLandUsePlan = async (data: any) => {
//   const response = await client.post(`/cities/${data.id}/landUsePlan/update`, {
//     landuseplan: data,
//   })

//   return response
// }

// const updateLandUsePlanAssignement = async (data: any) => {
//   const response = await client.post(`/cities/${data.id}/assignements/update`, {
//     landuseplanassignement: data,
//   })

//   return response
// }

// const addLandUsePlanHistory = async (lupId: string) => {
//   const response = await client.post(`/cities/${lupId}/history`, {
//     history: { description: '' },
//   })

//   return response
// }

// const updateLandUsePlanHistory = async ({
//   lupId,
//   historyId,
//   history,
// }: {
//   lupId: number | string
//   historyId: number | string
//   history: any
// }) => {
//   const response = await client.put(`/cities/${lupId}/history/${historyId}`, {
//     history,
//   })

//   return response
// }

// const deleteLandUsePlanHistory = async ({
//   lupId,
//   historyId,
// }: {
//   lupId: number | string
//   historyId: number | string
// }) => {
//   const response = await client.delete(`/cities/${lupId}/history/${historyId}`)

//   return response
// }

// const updateLandUsePlanDocument = async ({
//   document,
//   file,
//   path,
//   city,
//   landUsePlanId,
//   documents,
//   planType,
//   documentTypeId,
// }: any) => {
//   try {
//     const url = await client.put(
//       `/cities/${city}/landUsePlan/${planType}/${documentTypeId}/${path}/upload`,
//       { file },
//       { formData: true }
//     )

//     await client.put(
//       `/cities/${document.id}/document/${landUsePlanId}/update`,
//       {
//         ...document,
//         url: url.data,
//       }
//     )

//     return url
//   } catch (error) {
//     console.error(error)
//   }
// }

// const addLandUsePlanDocument = async ({ landUsePlanId }: any) => {
//   try {
//     const response = await client.post(
//       `/cities/${landUsePlanId}/landUsePlan/document`,
//       { name: '', url: '', typeId: 1 }
//     )

//     return response.data
//   } catch (error) {
//     console.error(error)
//   }
// }

// const deleteLandUsePlanDocument = async ({ documentId }: any) => {
//   try {
//     const response = await client.delete(
//       `/cities/${documentId}/landUsePlan/document/delete`
//     )

//     return response.data
//   } catch (error) {
//     console.error(error)
//   }
// }

const getAllAssignementsFuture = async (lupFutureId: string) => {
  const data = await client.get(`/cities/${lupFutureId}/futureassignements`)

  return data
}

const getFutureAssignementDetails = async (lupaFutureId: string) => {
  const data = await client.get(
    `/cities/${lupaFutureId}/futureassignements/details`,
  )

  return data
}

const getFutureLandUsePlansDocuments = async (lupFutureId: string) => {
  const data = await client.get(
    `/cities/${lupFutureId}/futureLandUsePlan/documents`,
  )

  return data
}

const getFutureLandUsePlanDetails = async (lupFutureId: string) => {
  const data = await client.get(
    `/cities/${lupFutureId}/futureLandUsePlan/details`,
  )

  return data
}

const addLandUsePlanFuture = async (landUsePlan: any) => {
  const data = await client.post(`/cities/futureLandUsePlans/add`, {
    landUsePlan,
  })

  return data
}

const addAssignementsFuture = async (assignement: any) => {
  const data = await client.post(`/cities/assignements/add`, {
    assignement,
  })

  return data
}

const updateFutureLandUsePlan = async (landUsePlan: any) => {
  await client.post(`/cities/${landUsePlan.id}/futureLandUsePlan/update`, {
    landUsePlan,
  })
}

const deleteFutureLandUsePlan = async (landUsePlan: any) => {
  await client.delete(`/cities/${landUsePlan.id}/futureLandUsePlan/delete`)
}

const updateFutureLandUsePlanDocument = async ({
  document,
  file,
  path,
  city,
  landUsePlanId,
  // documents,
  planType,
  documentTypeId,
}: any) => {
  try {
    const url = await client.put(
      `/cities/future/${city}/landUsePlan/${planType}/${documentTypeId}/${path}/upload`,
      { file },
      { formData: true },
    )

    await client.put(
      `/cities/future/${document.id}/document/${landUsePlanId}/update`,
      {
        ...document,
        url: url.data,
      },
    )

    return url
  } catch (error) {
    console.error(error)
  }
}

const addFutureLandUsePlanDocument = async ({ landUsePlanId }: any) => {
  try {
    const response = await client.post(
      `/cities/future/${landUsePlanId}/landUsePlan/document`,
      { name: '', url: '', typeId: 1 },
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

const deleteFutureLandUsePlanDocument = async ({ documentId }: any) => {
  try {
    const response = await client.delete(
      `/cities/future/${documentId}/landUsePlan/document/delete`,
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

const updateFutureLandUsePlanAssignement = async (data: any) => {
  const response = await client.post(
    `/cities/${data.id}/future/assignements/update`,
    {
      landuseplanassignement: data,
    },
  )

  return response
}

const locationsServices = {
  // getAllCities,
  // getAllLandUsePlans,
  getAllLandUsePlansFuture,
  getAllAssignementsFuture,
  getFutureAssignementDetails,
  getFutureLandUsePlansDocuments,
  getFutureLandUsePlanDetails,
  addLandUsePlanFuture,
  addAssignementsFuture,
  updateFutureLandUsePlan,
  deleteFutureLandUsePlan,
  updateFutureLandUsePlanDocument,
  addFutureLandUsePlanDocument,
  deleteFutureLandUsePlanDocument,
  updateFutureLandUsePlanAssignement,
  // getAllAssignements,
  // getAssignementDetails,
  // getLandUsePlansDocuments,
  // getSideDataBackAdmin,
  // getLandUsePlanDetails,
  // getHistoryBydId,
  // updateLandUsePlan,
  // updateLandUsePlanAssignement,
  // addLandUsePlanHistory,
  // updateLandUsePlanHistory,
  // deleteLandUsePlanHistory,
  // updateLandUsePlanDocument,
  // addLandUsePlanDocument,
  // deleteLandUsePlanDocument,
}

export default locationsServices
