import { Suspense, useMemo, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import {
  ProgressIndicator,
  useRouter,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useSelector, useDispatch } from 'react-redux'

import { getCompaniesMenuData } from './components/CompaniesMenu/CompaniesMenu.data'
import CompaniesSearch from './components/CompaniesSearch'
import {
  companyIdSelector,
  getAllOfficesByCompany,
  getAllContractsByCompany,
  getCompanySummary,
} from './redux'
import { CompaniesRoot } from './Companies.style'

const pages = getCompaniesMenuData()

const Companies = () => {
  const { query, updateQuery } = useRouter()

  const companyId = useSelector(companyIdSelector)

  const [openSearch, setOpenSearch] = useState(!query?.companyId)

  const dispatch = useDispatch<any>()

  useEffect(() => {
    setOpenSearch(!query.companyId)
  }, [query.companyId])

  const handleClose = () => {
    updateQuery({ companyId })
    setOpenSearch(false)
  }

  useEffect(() => {
    if (!query.companyId) return

    fetchOnce('contracts', query.companyId, () =>
      dispatch(getAllContractsByCompany(query.companyId)),
    )

    fetchOnce('offices', query.companyId, () =>
      dispatch(getAllOfficesByCompany(query.companyId)),
    )
  }, [query.companyId])

  useEffect(() => {
    if (!query.companyId) return

    fetchOnce('summary', query.companyId, () =>
      dispatch(getCompanySummary(query.companyId)),
    )
  }, [query.companyId])

  const content = useMemo(() => {
    return pages.find((p, i) => (!query.tab ? i === 0 : p.key === query.tab))
      ?.page
  }, [query.tab])

  return (
    <CompaniesRoot>
      <CompaniesSearch
        open={openSearch}
        showCloseIcon={!!companyId}
        onClose={handleClose}
        // setOpenSearch={setOpenSearch}e
      />
      <Suspense fallback={<ProgressIndicator circular />}>
        <Box width="100%">{content}</Box>
      </Suspense>
    </CompaniesRoot>
  )
}

export default Companies
