import pkg from '../../package.json'

/**
 * Indicates whether NODE_ENV is production
 * @namespace Config
 */
export const isProd = process.env.NODE_ENV === 'production'

/**
 * Indicates whether NODE_ENV is development
 * @namespace Config
 */
export const isDev = process.env.NODE_ENV === 'development'

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN as string

export const DEFAULT_PROFILE_IMG =
  'https://storage.googleapis.com/popetyio-web/user-company-logo/default.png'

export const APP_VERSION = process.env.REACT_APP_VERSION || pkg.version

/**
 * App pages. key is the unique key that identify each page. It is used to load i18n by page.
 * So add it must be unique and must match the its i18n folder like i18n/language/page where
 * page is the unique key of the page. Ex: i18n/fr/home
 * @namespace Config
 */
export const PAGES = [
  { pathname: '/companies', key: 'companies,companyDashboard,dashboard' },
  { pathname: '/login', key: 'login' },
  { pathname: '/faos', key: 'faos' },
  { pathname: '/pga_future', key: 'pga' },
  { pathname: '/pga', key: 'pga' },
] as const
