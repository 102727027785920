import { createSelector } from '@reduxjs/toolkit'

const contractsStateSelector = (state) => state.contracts

const contractsByIdSelector = (state) => state.contracts.contractsById

const contractsSelector = createSelector(contractsByIdSelector, (contracts) =>
  Object.values(contracts)?.filter((val) => val?.id),
)

const contractsLoadingSelector = createSelector(
  contractsStateSelector,
  (contracts) => contracts.loading,
)

const subscriptionsTypeSelector = createSelector(
  contractsStateSelector,
  (contracts) => contracts.subscriptionsType,
)

export {
  contractsByIdSelector,
  contractsSelector,
  contractsLoadingSelector,
  subscriptionsTypeSelector,
}
