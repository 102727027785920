import { combineReducers } from '@reduxjs/toolkit'

import { authReducer } from '../pages/Login'
import {
  companiesReducer,
  usersReducer,
  officesReducer,
  contractsReducer,
  loginHistoryReducer,
} from '../pages/Companies'
import { dashboardReducer } from '../pages/Dashboard'
import { supportReducer } from '../pages/Supports'
import { locationsReducer } from '../pages/Pga/redux'
import { unclassifiedFaosReducer } from '../pages/Faos/redux'
import { locationsFutureReducer } from '../pages/PgaFuture/redux'

const rootReducer = combineReducers({
  ...authReducer,
  ...companiesReducer,
  ...usersReducer,
  ...officesReducer,
  ...loginHistoryReducer,
  ...contractsReducer,
  ...supportReducer,
  ...locationsReducer,
  ...unclassifiedFaosReducer,
  ...locationsFutureReducer,
  ...dashboardReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }

  return rootReducer(state, action)
}
