import { ID } from '@popety_io/popety-io-lib'
import { ApiClient } from '../../../../services'
// TODO: Fix this issue
// eslint-disable-next-line import/no-cycle

const client = new ApiClient()

type activeOrInactivateProps = {
  id: string | number
  active: boolean | string
}

const getAllOfficesByCompany = async (id: number | string) => {
  return client.get(`/company/${id}/offices`)
}

const activeOrInactivateOffice = async ({
  id,
  active,
}: activeOrInactivateProps) => {
  const response = active
    ? await client.put(`/company/offices/${id}/inactivate`)
    : await client.put(`/company/offices/${id}/activate`)

  if (response.data) {
    response.data = { id, status: !active }
  }

  return response
}

const updateOfficeLogo = async ({ file, id }: any) => {
  return client.put(
    `/company/offices/${id}/update-logo`,
    { file },
    { formData: true },
  )
}

const addOffice = async ({ input, companyId }: any) => {
  let file: { data?: string } = {}

  const { data } = await client.post(`/company/${companyId}/offices/add`, input)

  if (data?.id && input.file) {
    file = await updateOfficeLogo({ id: data.id, file: input.file })
  }

  const logo = data.logo || file?.data

  return { ...data, logo }
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const updateOffice = async ({ file, companyId, ...input }: any) => {
  const res = await client.put(`/company/offices/update/${input.id}`, input)

  if (res?.data?.id && file) {
    const { data } = await updateOfficeLogo({ id: res.data.id, file })

    res.data.logo = data
  }

  return res
}

type LogoOfficeProps = {
  file: File | Blob
  id: ID
}

const updateLogoOffice = async ({ id, file }: LogoOfficeProps) => {
  return client.put(
    `/company/offices/${id}/update-logo`,
    { file },
    { formData: true },
  )
}

const deleteOffice = async (id: string | number) => {
  client.delete(`/company/offices/delete/${id}`)
}

const officesServices = {
  getAllOfficesByCompany,
  activeOrInactivateOffice,
  addOffice,
  updateOffice,
  updateLogoOffice,
  deleteOffice,
}

export default officesServices
