import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const CompaniesActions = lazy(
  () => import('../../pages/Companies/components/CompaniesRightActions'),
)

const HeaderRight = () => (
  <Suspense>
    <Routes>
      <Route path="/companies" element={<CompaniesActions />} />
    </Routes>
  </Suspense>
)

export default memo(HeaderRight)
