import { Autocomplete, Box } from '@mui/material'
import { Tag } from '@popety_io/popety-io-lib'
import { TagFilterInputRoot } from './SelectTagFilter.style'

const MulltipleOptionsInput = ({
  selectedOptions,
  options,
  onSelectOption,
  title,
}: {
  selectedOptions: any
  options: any
  onSelectOption: any
  title: string
}) => {
  return (
    <Autocomplete
      value={selectedOptions}
      options={options}
      onChange={onSelectOption}
      multiple
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      sx={{
        '& .MuiSelect-select': {
          py: '10px',
        },
      }}
      renderOption={(props, option: any) => (
        <Box
          component="li"
          p={0.5}
          {...props}
          key={`tag_suggestion_${option?.value}`}
          sx={{
            gap: 1,
          }}
        >
          <Tag sx={{ display: 'block', cursor: 'pointer' }}>
            {option?.label}
          </Tag>
        </Box>
      )}
      renderInput={(params) => <TagFilterInputRoot {...params} label={title} />}
    />
  )
}

export default MulltipleOptionsInput
