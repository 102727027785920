import { lazy } from 'react'
import SummarizeIcon from '@mui/icons-material/Summarize'
import AssignmentIcon from '@mui/icons-material/Assignment'
import BusinessIcon from '@mui/icons-material/Business'
import PeopleIcon from '@mui/icons-material/People'
import { HelpIcon } from '@popety_io/popety-io-lib'
import { Login } from '@mui/icons-material'

const Summary = lazy(() => import('../../Summary'))

const Users = lazy(() => import('../../Users'))

const Offices = lazy(() => import('../../Offices'))

const Contract = lazy(() => import('../../Contract'))

const Support = lazy(() => import('../../../Supports'))

const LoginHistory = lazy(() => import('../../LoginHistory'))

const getCompaniesMenuData = (t = (k: string) => k) => [
  {
    icon: <SummarizeIcon />,
    key: 'summary',
    text: t('common.companies.Summary'),
    page: <Summary />,
  },
  {
    icon: <PeopleIcon />,
    key: 'users',
    text: t('common.companies.Users'),
    page: <Users />,
  },
  {
    icon: <BusinessIcon />,
    key: 'offices',
    text: t('common.companies.Offices'),
    page: <Offices />,
  },
  {
    icon: <AssignmentIcon />,
    key: 'contract',
    text: t('common.companies.Contract'),
    page: <Contract />,
  },
  {
    icon: <HelpIcon />,
    key: 'support',
    text: t('common.companies.Support'),
    page: <Support />,
  },
  {
    icon: <Login />,
    key: 'history',
    text: t('common.companies.LoginHistory'),
    page: <LoginHistory />,
  },
]

export { getCompaniesMenuData }
