import { useI18n } from '@popety_io/popety-io-lib'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CountriesSearch from '../Summary/components/FormCompany/CountriesSearch'

type CompanyInfosFormProps = {
  values: {
    name?: string
    address?: string
    postalCode?: string
    city?: string
    country?: string
    website?: string
    template_email_address?: string
  }
  onChange: (e: any) => void
  errors: {
    name?: string
    address?: string
    postalCode?: string
    city?: string
    country?: string
    website?: string
    template_email_address?: string
  }
}

const CompanyInfosForm = ({
  values,
  errors,
  onChange,
}: CompanyInfosFormProps) => {
  const { t } = useI18n()

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="company-name"
          label={t('companyDashboard.Company name')}
          value={values?.name}
          onChange={onChange}
          name="name"
          fullWidth
          error={!!errors?.name}
          helperText={errors?.name}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="address"
          label={t('companyDashboard.Address')}
          value={values?.address}
          onChange={onChange}
          name="address"
          fullWidth
          error={!!errors?.address}
          helperText={errors?.address}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="postalCode"
          label={t('companyDashboard.Postal code')}
          value={values?.postalCode}
          onChange={onChange}
          name="postalCode"
          fullWidth
          error={!!errors?.postalCode}
          helperText={errors?.postalCode}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="city"
          label={t('companyDashboard.City')}
          value={values?.city}
          onChange={onChange}
          name="city"
          fullWidth
          error={!!errors?.city}
          helperText={errors?.city}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CountriesSearch
          label={t('companyDashboard.Country')}
          value={values?.country as any}
          onChange={(value: any) =>
            onChange({ target: { name: 'country', value } })
          }
          error={!!errors?.country}
          helperText={errors?.country}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="website"
          label={t('companyDashboard.Website')}
          value={values?.website}
          onChange={onChange}
          name="website"
          fullWidth
          error={!!errors?.website}
          helperText={errors?.website}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="email-address"
          label={t('companyDashboard.Email Address')}
          value={values?.template_email_address}
          onChange={onChange}
          name="template_email_address"
          fullWidth
          error={!!errors?.template_email_address}
          helperText={errors?.template_email_address}
        />
      </Grid>
    </>
  )
}

export default CompanyInfosForm
