import { createSelector } from '@reduxjs/toolkit'

import { DEFAULT_PROFILE_IMG } from '../../../config'

const authSelector = (state) => state.auth

const authUserSelector = (state) => state.auth.user

const supportsSelector = (state) => state.auth.supports

const userSelector = createSelector(authUserSelector, (user) => {
  if (!user?.id) return null

  return {
    ...user,
    name: `${user.first_name} ${user.last_name}`,
    companyLogo: user.company_logo || DEFAULT_PROFILE_IMG,
  }
})

const loggedSelector = (state) => state.auth.logged

const appSelector = createSelector(userSelector, (user) => {
  try {
    if (user.app) return user.app

    let app = ''
    const backPopety = user?.roles?.includes('Back_popety')

    if (backPopety) {
      app = backPopety ? 'Back_popety' : ''
    }

    return app
  } catch (error) {
    console.error(error)

    return ''
  }
})

const authRolesSelector = createSelector(authUserSelector, (user) => {
  return user?.roles || []
})

const isCompanyAdminSelector = createSelector(authRolesSelector, (roles) => {
  return roles.includes('Admin_company')
})

export {
  authSelector,
  authRolesSelector,
  userSelector,
  loggedSelector,
  appSelector,
  isCompanyAdminSelector,
  supportsSelector,
}
