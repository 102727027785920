/* eslint-disable max-lines */
import { memo, useEffect } from 'react'
import { Menu, LanguageMenu, useAsync, useI18n } from '@popety_io/popety-io-lib'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/People'
import BarChartIcon from '@mui/icons-material/BarChart'
import LayersIcon from '@mui/icons-material/Layers'
import FutureLayersIcon from '@mui/icons-material/LayersTwoTone'
import CachedIcon from '@mui/icons-material/Cached'
import LogoutIcon from '@mui/icons-material/Logout'

import Settings from '@mui/icons-material/Settings'
import {
  userSelector,
  logout,
  forgetAuthUser,
  authRolesSelector,
} from '../../pages/Login'
import { APP_VERSION } from '../../config'
import { API_BASE_URL } from '../../services'
import authService from '../../pages/Login/redux/authService'
import { clearAppDataAndCache } from './appMenu.utils'

const AppMenu = () => {
  const user = useSelector(userSelector)
  const roles = useSelector(authRolesSelector)
  const navigate = useNavigate()
  const async = useAsync()

  const dispatch = useDispatch()

  const { currentLanguage, setCurrentLanguage, t } = useI18n()

  const handleLangageChange = (language: string) => {
    setCurrentLanguage(language)
  }

  const handleLogin = () => {
    navigate('/login', { replace: true })
  }

  const handleLogout = () => {
    dispatch(logout())
    dispatch(forgetAuthUser())
    sessionStorage.clear()
    navigate('/login', { replace: true })
  }

  useEffect(() => {
    async.dispatch(() => authService.getApiVersion())
  }, [])

  const menus = {
    title: user ? `${user.first_name} ${user.last_name}` : t('common.logIn'),
    subTitle: user?.email || '',
    photo: 'logo.png',
    homeUrl: '/',
    data: !user
      ? []
      : [
          {
            href: '/companies',
            text: t('common.companies.Companies'),
            icon: <PeopleIcon />,
            admin: true,
          },
          {
            href: '/pga',
            text: t('common.PGA'),
            icon: <LayersIcon />,
            pga: true,
          },
          {
            href: '/pga_future',
            text: t('common.PGA Future'),
            icon: <FutureLayersIcon />,
            pga: true,
          },
          {
            href: '/faos',
            text: t('common.faos'),
            icon: <BarChartIcon />,
            admin: true,
          },
          {
            href: '/setting',
            text: t('common.settings'),
            icon: <Settings />,
            admin: true,
          },
        ].filter(
          (item) =>
            (!item.admin && roles.includes('Back_pga')) ||
            roles.includes('Back_popety') ||
            roles.includes('Admin'),
        ),
    footerMenus: [
      { text: APP_VERSION, sx: { color: 'text.secondary' } },
      {
        text: `Api: ${async.data}`,
        sx: { color: 'text.secondary' },
        hide: API_BASE_URL?.includes('prod'),
      },
    ],
    rightMenus: [
      {
        order: 1,
        component: (
          <LanguageMenu
            lang={currentLanguage as any}
            onChange={handleLangageChange}
          />
        ),
      },
      {
        order: 2,
        subMenus: [
          {
            order: 2,
            onClick: handleLogout,
            text: t('common.signOut'),
            icon: <LogoutIcon />,
          },
        ],
      },
      {
        order: 2,
        subMenus: [
          {
            order: 2,
            onClick: clearAppDataAndCache,
            text: t('common.updateApp'),
            icon: <CachedIcon />,
          },
        ],
      },
    ],
  }

  return (
    <Menu
      {...(menus as any)}
      sx={{ '&.MenuList': { flexGrow: 1, justifyContent: 'flex-end' } }}
      onLogin={user ? undefined : handleLogin}
    />
  )
}

export default memo(AppMenu)
