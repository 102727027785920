// import { createSelector } from '@reduxjs/toolkit'

// const locationsSelector = (state) => state.locations

const locationsFutureSelector = (state) => state.locationsFuture

const landUsePlansFutureSelector = (state) =>
  state.locationsFuture.futureLandUsePlans

const futureAssignementsSelector = (state) =>
  state.locationsFuture.futureAssignements

const futureAssignementDetailsSelector = (state) =>
  state.locationsFuture.futureAssignementDetails

const futureDocumentsSelector = (state) => state.locationsFuture.futureDocuments

const futureLupDetailsSelector = (state) =>
  state.locationsFuture.futureLupDetails

const futureLandUsePlanUpdateSelector = (state) =>
  state.locationsFuture.futureLandUsePlanUpdate

const landUsePlanFutureCopySelector = (state) =>
  state.locationsFuture.futureLandUsePlansCopy

const assignementsCopySelector = (state) =>
  state.locationsFuture.futureAssignementsCopy

const futureAssignementCopySelector = (state) =>
  state.locationsFuture.futureAssignementCopyObject

export {
  landUsePlansFutureSelector,
  locationsFutureSelector,
  futureAssignementsSelector,
  futureAssignementDetailsSelector,
  futureDocumentsSelector,
  futureLupDetailsSelector,
  futureLandUsePlanUpdateSelector,
  landUsePlanFutureCopySelector,
  assignementsCopySelector,
  futureAssignementCopySelector,
}
