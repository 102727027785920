import { ApiClient } from '../../../services'

const client = new ApiClient()

type AnalysisPdfLogsProps = {
  limit: string | number
  offset: string | number
  typeSearch?: string
  userId?: string | number
}

const getOtherAnalytics = async () => {
  return client.get(`/back-office/analytics/other`)
}

const getUserActiveAnalysis = async () => {
  return client.get(`/back-office/analytics/users`)
}

const getContractAnalytics = async () => {
  return client.get(`/back-office/analytics/contract`)
}

const getExportAnalytics = async () => {
  return client.get(`/back-office/analytics/export`)
}
const getCompaniesAnalytics = async () => {
  return client.get(`/back-office/analytics/companies`)
}
const getHomeAnalytics = async () => {
  return client.get(`/back-office/analytics/home`)
}
const getSearchAnalytics = async () => {
  return client.get('/back-office/analytics/search')
}
const getAnalysisPdfLogs = async ({
  limit = 25,
  offset = 0,
  typeSearch = '',
  userId = '',
}: AnalysisPdfLogsProps) => {
  return client.post(
    `/back-office/analytics/${limit || 25}/${offset || 0}/analysis-pdf-logs`,
    { typeSearch, userId },
  )
}

const dashboardServices = {
  getOtherAnalytics,
  getUserActiveAnalysis,
  getContractAnalytics,
  getExportAnalytics,
  getCompaniesAnalytics,
  getHomeAnalytics,
  getSearchAnalytics,
  getAnalysisPdfLogs,
}

export default dashboardServices
