import { ApiClient } from '../../../services'

const client = new ApiClient()

const getSupports = () => {
  return client.get(`/supports/1`)
}

const updateSupports = (input: Record<string, any>) => {
  return client.post(`/supports`, input)
}

const supportService = { updateSupports, getSupports }

export default supportService
