import { createSelector } from '@reduxjs/toolkit'

const usersStateSelector = (state) => state.users

const accountManagersSelector = (state) => state.users.accountManagers

const accountManagersByIdSelector = (state) => state.users.accountManagersById

const usersByIdSelector = (state) => state.users.usersById

const usersSelector = createSelector(usersByIdSelector, (users) =>
  Object.values(users).filter((val) => val?.id),
)

const activeUsersSelector = createSelector(usersSelector, (users) =>
  users?.filter((user) => user.status === 'active'),
)

const inactiveUsersSelector = createSelector(usersSelector, (users) =>
  users.filter((user) => user.status === 'inactive'),
)

const usersLoadingSelector = createSelector(
  usersStateSelector,
  (users) => users.loading,
)

const countUsersSelector = createSelector(
  usersStateSelector,
  (users) => users.count,
)

export {
  usersSelector,
  activeUsersSelector,
  inactiveUsersSelector,
  usersLoadingSelector,
  countUsersSelector,
  accountManagersSelector,
  accountManagersByIdSelector,
}
