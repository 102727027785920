import { createSelector } from '@reduxjs/toolkit'

const companiesStateSelector = (state) => state.companies

const companiesLoadingSelector = (state) => state.companies.loading

const companiesByIdSelector = (state) => state.companies.companiesById

const companiesSelector = createSelector(companiesByIdSelector, (companies) =>
  Object.values(companies),
)

const loadingCompaniesSelector = (state) => state.companies.loading

const companySelector = (state) => state.companies.company

const companyIdSelector = (state) => state.companies.company?.id

const companyLoadingSelector = (state) => state.companies.loadingSummary

const countriesSelector = (state) => state.companies.countries || []

const usersSearchSelector = (state) => state.companies.users || []

const usersSearchLoadingSelector = (state) => state.companies.loadingUser

export {
  companiesStateSelector,
  companiesLoadingSelector,
  companiesSelector,
  loadingCompaniesSelector,
  companySelector,
  companyLoadingSelector,
  companyIdSelector,
  countriesSelector,
  usersSearchSelector,
  usersSearchLoadingSelector,
}
