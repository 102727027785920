import { BaseLayout } from '@popety_io/popety-io-lib'

import AppMenu from './AppMenu'
import HeaderActions from './HeaderActions'
import HeaderLeft from './HeaderLeft'
import HeaderRight from './HeaderRight'
import SideBox from './SideBox'

const Layout = ({ children }: any) => {
  return (
    <BaseLayout
      SideBox={<SideBox />}
      HeaderActions={<HeaderActions />}
      HeaderLeft={<HeaderLeft />}
      HeaderRight={<HeaderRight />}
      Menu={AppMenu}
    >
      {children}
    </BaseLayout>
  )
}

export default Layout
