import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const CompaniesMenu = lazy(
  () => import('../../pages/Companies/components/CompaniesMenu'),
)
const DashboardMenu = lazy(
  () => import('../../pages/Dashboard/components/DashboardMenu'),
)
const SettingsMenu = lazy(
  () => import('../../pages/Settings/components/SettingsMenu'),
)

const SideBox = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/setting" element={<SettingsMenu />} />
        <Route path="/companies" element={<CompaniesMenu />} />
        <Route path="/" element={<DashboardMenu />} />
      </Routes>
    </Suspense>
  )
}

export default memo(SideBox)
