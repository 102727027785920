import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'

type UploadLogoProps = {
  preview: string
  handleUpload: () => void
}

const UploadLogo = ({ preview, handleUpload }: UploadLogoProps) => {
  return (
    <Grid item xs={12} sm={6} display="flex" justifyContent="center" mt={2}>
      <Avatar
        style={{ height: 200, width: 200 }}
        alt="user-photo"
        src={preview}
      />
      <IconButton
        style={{ position: 'absolute', marginLeft: 200, marginTop: 145 }}
        aria-label="edit"
        onClick={handleUpload}
      >
        <EditIcon />
      </IconButton>
    </Grid>
  )
}

export default UploadLogo
