import { ApiClient } from '../../../services'

const client = new ApiClient()

const getUnclassifiedFaos = async () => {
  try {
    const response = await client.get('/faos/unclassified')

    return response
  } catch (error) {
    console.error(error)
  }
}

const putUnclassifiedFaos = async ({ currentDate, selectedFaos }: any) => {
  try {
    const response = await client.put('/faos/unclassified/add', {
      currentDate,
      selectedFaos,
    })

    return response
  } catch (error) {
    console.error(error)
  }
}

const faosService = { getUnclassifiedFaos, putUnclassifiedFaos }

export default faosService
