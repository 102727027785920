import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import supportService from './supportService'

const initialState = {
  support: undefined,
}

export const updateSupports = createAsyncThunk(
  'support/updateSupports',
  supportService.updateSupports,
)

export const getSupports = createAsyncThunk(
  'support/getSupports',
  supportService.getSupports,
)

const support = createSlice({
  name: 'support',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder.addCase(getSupports.fulfilled, (state, action) => {
      const { data } = action.payload || {}

      state.supports = data
    })
  },
})

export const supportReducer = {
  support: support.reducer,
}
