import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import authService from './authService'

const initialState = {
  user: JSON.parse(localStorage.getItem('user')),
  isLoggedIn: !!localStorage.getItem('jwt'),
}

export const login = createAsyncThunk('auth/login', authService.login)

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeApp: (state, action) => {
      state.user = { ...state.user, app: action.payload }
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    forgetAuthUser: (state) => {
      state.user = null
      state.isLoggedIn = false

      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
    },
    updateLocalUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    logout: () => {
      // TODO: From here we can take action only at this "auth" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    },
  },
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder.addCase(login.fulfilled, (state, action) => {
      const { data, errors } = action.payload || {}

      if (data) {
        state.user = data.user
        const backPopety = data.user?.roles.includes('Back_popety')

        if (backPopety) {
          state.user = {
            ...state.user,
            app: backPopety ? 'Back_popety' : '',
          }
        }

        state.logged = true
      }

      if (errors) {
        state.user = null
        state.logged = false

        state.user = { ...state.user, app: '' }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const { forgetAuthUser, changeApp, updateLocalUser, logout } =
  auth.actions

export const authReducer = {
  auth: auth.reducer,
}
