/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dashboardServices from './dashboardServices'
import { IDashboardState } from './dashboardModel'

const initialState: IDashboardState = {
  analytics: {
    users: {
      active: [],
      created: [],
      deleted: [],
    },
    other: {
      alerts: [],
      buildingFavorites: [],
      landFavorites: [],
      notes: [],
      tagsApplied: [],
      tagsCreated: [],
    },
    contract: {
      created: [],
      deleted: [],
    },
    export: {
      building: [],
      land: [],
      typeExports: [],
      analysisPdfLogs: [],
      countAnalysisPdfLogs: 0,
    },
    companies: {
      active: [],
      created: [],
      deleted: [],
    },
    home: {
      building: [],
      users: {
        active: [],
        created: [],
      },
      alerts: [],
      logSearchExplore: [],
      emails: [],
      estimateEdit: [],
    },
    search: {
      land: [],
      building: [],
      transaction: [],
      listing: [],
      publicInquire: [],
      inPlot: [],
      inBuilding: [],
    },
  },
  loading: false,
}

export const getUserActiveAnalysis = createAsyncThunk(
  'dashboard/getUserActiveAnalysis',
  dashboardServices.getUserActiveAnalysis,
)

export const getContractAnalytics = createAsyncThunk(
  'dashboard/getContractAnalytics',
  dashboardServices.getContractAnalytics,
)

export const getOtherAnalytics = createAsyncThunk(
  'dashboard/getOtherAnalytics',
  dashboardServices.getOtherAnalytics,
)

export const getExportAnalytics = createAsyncThunk(
  'dashboard/getExportAnalytics',
  dashboardServices.getExportAnalytics,
)

export const getCompaniesAnalytics = createAsyncThunk(
  'dashboard/getCompaniesAnalytics',
  dashboardServices.getCompaniesAnalytics,
)

export const getHomeAnalytics = createAsyncThunk(
  'dashboard/getHomeAnalytics',
  dashboardServices.getHomeAnalytics,
)

export const getSearchAnalytics = createAsyncThunk(
  'dashboard/getSearchAnalytics',
  dashboardServices.getSearchAnalytics,
)

export const getAnalysisPdfLogs = createAsyncThunk(
  'dashboard/getAnalysisPdfLogs',
  dashboardServices.getAnalysisPdfLogs,
)

export const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserActiveAnalysis.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserActiveAnalysis.rejected, (state) => {
        state.loading = false
      })
      .addCase(getUserActiveAnalysis.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.users.active = data.active
          state.analytics.users.created = data.created
          state.analytics.users.deleted = (data?.deleted || [])?.map(
            (u: any) => ({
              ...u,
              count: u.number_user_del,
            }),
          )
        }
      })
      .addCase(getContractAnalytics.pending, (state) => {
        state.loading = true
      })
      .addCase(getContractAnalytics.rejected, (state) => {
        state.loading = false
      })
      .addCase(getContractAnalytics.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.contract.created = data.created
          state.analytics.contract.deleted = data.deleted
        }
      })
      .addCase(getOtherAnalytics.pending, (state) => {
        state.loading = true
      })
      .addCase(getOtherAnalytics.rejected, (state) => {
        state.loading = false
      })
      .addCase(getOtherAnalytics.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.other.alerts = data.alerts
          state.analytics.other.buildingFavorites = data.buildingFavorites
          state.analytics.other.landFavorites = data.landFavorites
          state.analytics.other.notes = data.notes
          state.analytics.other.tagsApplied = data.tagsApplied
          state.analytics.other.tagsCreated = data.tagsCreated
        }
      })
      .addCase(getExportAnalytics.pending, (state) => {
        state.loading = true
      })
      .addCase(getExportAnalytics.rejected, (state) => {
        state.loading = false
      })
      .addCase(getExportAnalytics.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.export.land = data.land
          state.analytics.export.building = data.building
          state.analytics.export.typeExports = data.typeExports
        }
      })
      .addCase(getCompaniesAnalytics.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompaniesAnalytics.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCompaniesAnalytics.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.companies.active = data.active
          state.analytics.companies.created = data.created
          state.analytics.companies.deleted = data.deleted
        }
      })
      .addCase(getHomeAnalytics.pending, (state) => {
        state.loading = true
      })
      .addCase(getHomeAnalytics.rejected, (state) => {
        state.loading = false
      })
      .addCase(getHomeAnalytics.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.home.users.active = data.activeUsers
          state.analytics.home.users.created = data.createdUsers
          state.analytics.home.building = data.buildingAnalysis
          state.analytics.home.alerts = data.alerts
          state.analytics.home.logSearchExplore = data.logSearchExplore
          state.analytics.home.emails = data.emails
          state.analytics.home.estimateEdit = data.estimateEdit
        }
      })
      .addCase(getSearchAnalytics.pending, (state) => {
        state.loading = true
      })
      .addCase(getSearchAnalytics.rejected, (state) => {
        state.loading = false
      })
      .addCase(getSearchAnalytics.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.search.building = data.building
          state.analytics.search.inBuilding = data.inBuilding
          state.analytics.search.inPlot = data.inPlot
          state.analytics.search.land = data.land
          state.analytics.search.listing = data.listing
          state.analytics.search.publicInquire = data.publicInquire
          state.analytics.search.transaction = data.transaction
        }
      })
      .addCase(getAnalysisPdfLogs.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnalysisPdfLogs.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAnalysisPdfLogs.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data) {
          state.analytics.export.analysisPdfLogs = data?.data || []
          state.analytics.export.countAnalysisPdfLogs = data?.count || 0
        }
      })
  },
})

export const dashboardReducer = {
  dashboard: dashboard.reducer,
}
