import { ApiClient, API_BASE_URL } from '../../../services'

const client = new ApiClient()

type LoginInput = { email: string; password: string }

const login = async (input: LoginInput) => {
  try {
    const response = await client.post('/auth/login', {
      ...input,
      platform: 'back_V2',
    })
    const payload = response.data

    if (payload) {
      // Store token and user to local storage
      localStorage.setItem('jwt', payload.token)
      localStorage.setItem('user', JSON.stringify(payload.user))
    }

    if (response.errors) {
      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
    }

    return response
  } catch (error) {
    console.error(error)
  }
}

const getApiVersion = async () => {
  const url = API_BASE_URL?.replace('api/v2', 'version') as string
  const response = await fetch(url, { method: 'GET' })

  const version = await response.text()

  return version
}

const authService = { login, getApiVersion }

export default authService
