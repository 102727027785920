/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mapBy } from '@popety_io/popety-io-lib'

import companiesService from './companiesService'

const initialState = {
  loading: false,
  companiesById: {},
  company: {},
  users: [],
  loadingUser: false,
}

export const getAllCompanies = createAsyncThunk(
  'companies/getAllCompanies',
  companiesService.getAllCompanies,
)

export const getCompanySummary = createAsyncThunk(
  'companies/getCompanySummary',
  companiesService.getCompanySummary,
)

export const addCompany = createAsyncThunk(
  'companies/addCompany',
  companiesService.addCompany,
)

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  companiesService.updateCompany,
)

export const updatePolicy = createAsyncThunk(
  'companies/updatePolicy',
  companiesService.updatePolicy,
)

export const getCountries = createAsyncThunk(
  'companies/getCountries',
  companiesService.getCountries,
)

export const updateCompanyColors = createAsyncThunk(
  'companies/updateCompanyColors',
  companiesService.updateCompanyColors,
)

export const getUsersSearch = createAsyncThunk(
  'companies/users',
  companiesService.getUsers,
)

export const companies = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompanies.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllCompanies.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.countries = data
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false
        if (data) {
          state.companiesById = mapBy('id', data)
        }
      })
      .addCase(getCompanySummary.pending, (state) => {
        state.loadingSummary = true
      })
      .addCase(getCompanySummary.rejected, (state) => {
        state.loadingSummary = false
      })
      .addCase(getCompanySummary.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.loadingSummary = false
        state.company = data
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data && input?.id === state.company?.id) {
          state.company = { ...state.company, ...input }
        }
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data?.id) {
          state.companiesById[data.id] = { ...input.body, id: data.id }
        }
      })
      .addCase(updatePolicy.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { input } = action.meta.arg

        if (data) {
          state.companiesById[data.id] = {
            ...state.companiesById[data.id],
            policy: input,
          }
        }
      })
      .addCase(updateCompanyColors.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const colors = action.meta.arg

        if (data) {
          state.company = {
            ...state.company,
            colors: { primary: colors?.primary, secondary: colors?.secondary },
          }
        }
      })
      .addCase(getUsersSearch.pending, (state) => {
        state.loadingUser = true
      })
      .addCase(getUsersSearch.rejected, (state) => {
        state.loadingUser = false
      })
      .addCase(getUsersSearch.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data?.length) {
          state.users = data?.map((item) => {
            return {
              id: item?.id,
              company_id: item?.company_id,
              name: `${item?.first_name || ''} ${item?.last_name || ''}`,
            }
          })
        }
        state.loadingUser = false
      })
  },
})

export const companiesReducer = {
  companies: companies.reducer,
}
