/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mapBy } from '@popety_io/popety-io-lib'

import { updateUsersById, addUserById } from '../Companies.utils'

import usersServices from './usersServices'

const initialState = {
  loading: false,
  usersById: {},
  accountManagers: [],
  accountManagersById: {},
}

export const getAllUsersByCompany = createAsyncThunk(
  'companies/getAllUsersByCompany',
  usersServices.getAllUsersByCompany,
)

export const activeOrInactivate = createAsyncThunk(
  'usersServices/activeOrInactivate',
  usersServices.activeOrInactivate,
)

export const addUser = createAsyncThunk(
  'usersServices/addUser',
  usersServices.addUser,
)

export const updateUser = createAsyncThunk(
  'usersServices/updateUser',
  usersServices.updateUser,
)

export const deleteUser = createAsyncThunk(
  'usersService/deleteUser',
  usersServices.deleteUser,
)

export const getAccountManagers = createAsyncThunk(
  'usersService/getAccountManagers',
  usersServices.getAccountManagers,
)

export const resendWelcomeEmail = createAsyncThunk(
  'usersService/resendWelcomeEmail',
  usersServices.resendWelcomeEmail,
)

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersByCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllUsersByCompany.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAllUsersByCompany.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false

        if (data.data) {
          state.usersById = mapBy('id', data.data)
          state.count = data.count
        }
      })
      .addCase(activeOrInactivate.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        if (data) {
          state.usersById[data.id] = {
            ...state.usersById[data.id],
            ...data,
          }
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const data = action.payload
        const input = action.meta.arg

        if (data) {
          state.usersById[input.userId] = {}
        }
      })
      .addCase(updateUser.fulfilled, updateUsersById)
      .addCase(addUser.fulfilled, addUserById)
      .addCase(getAccountManagers.pending, (state) => {
        state.loading = true
      })
      .addCase(getAccountManagers.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAccountManagers.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.loading = false
        if (data) {
          if (Array.isArray(data)) {
            state.accountManagersById = mapBy('id', data)
          }
          state.accountManagers = data
        }
      })
      .addCase(resendWelcomeEmail.pending, (state) => {
        state.loading = true
      })
      .addCase(resendWelcomeEmail.rejected, (state) => {
        state.loading = false
      })
      .addCase(resendWelcomeEmail.fulfilled, (state) => {
        state.loading = false
      })
  },
})

export const usersReducer = {
  users: users.reducer,
}
