import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const TagFilterInputRoot = styled(TextField)`
  flex-grow: 1;
  .MuiOutlinedInput-root {
    margin: 0;

    &.MuiInputBase-sizeSmall {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
  .MuiAutocomplete-tag {
    height: 20px;
    margin: 2px;
    .MuiChip-deleteIcon {
      font-size: 1rem;
    }
  }
`
