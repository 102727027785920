/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mapBy } from '@popety_io/popety-io-lib'
import { updateOfficesById } from '../Companies.utils'

import officesServices from './officesServices'

const initialState = {
  loading: false,
  officesById: {},
}

export const getAllOfficesByCompany = createAsyncThunk(
  'officesServices/getAllOfficesByCompany',
  officesServices.getAllOfficesByCompany,
)

export const activeOrInactivateOffice = createAsyncThunk(
  'officesServices/activeOrInactivateOffice',
  officesServices.activeOrInactivateOffice,
)

export const addOffice = createAsyncThunk(
  'officesServices/addOffice',
  officesServices.addOffice,
)

export const updateOffice = createAsyncThunk(
  'officesServices/updateOffice',
  officesServices.updateOffice,
)

export const deleteOffice = createAsyncThunk(
  'officesServices/deleteOffice',
  officesServices.deleteOffice,
)

export const offices = createSlice({
  name: 'offices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllOfficesByCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllOfficesByCompany.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAllOfficesByCompany.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false
        state.officesById = mapBy('id', data)
      })
      .addCase(activeOrInactivateOffice.fulfilled, updateOfficesById)
      .addCase(addOffice.fulfilled, (state, action) => {
        const data = action.payload
        const { input } = action.meta.arg

        if (data) {
          state.officesById[data.id] = {
            ...state.officesById[data.id],
            ...input,
            ...data,
            status: true,
            active: true,
          }
        }
      })
      .addCase(updateOffice.fulfilled, updateOfficesById)
      .addCase(deleteOffice.fulfilled, (state, { meta }) => {
        const input = meta.arg

        if (input) {
          state.officesById[input] = {}
        }
      })
  },
})

export const officesReducer = {
  offices: offices.reducer,
}
