import { Link, Navigate, useLocation } from 'react-router-dom'
import { LoginForm, Alert, useI18n } from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'

import { useThunk } from '../../hooks'
import { login } from './redux'
import { LoginRoot } from './Login.style'

const Login = () => {
  const { dispatch, loading, errors } = useThunk()
  const jwt = typeof localStorage !== 'undefined' && localStorage.getItem('jwt')

  const { t } = useI18n()
  const { state } = useLocation()

  const from =
    state?.from ||
    (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('from'))

  const handleSubmit = (values: Record<string, any>) => {
    dispatch(() => login(values as any))
  }

  if (jwt && !loading) return <Navigate to={from || '/'} />

  return (
    <LoginRoot>
      <Typography variant="inherit" component="h1">
        POPETY
      </Typography>
      <Typography>{t('login.title')}</Typography>

      <LoginForm
        passwordLabel={t('login.password')}
        emailLabel={t('login.email')}
        loading={loading}
        submitText={t('login.signin')}
        onSubmit={handleSubmit}
        emailRequired={t('common.email.required')}
        passwordRequired={t('common.password.required')}
        invalidEmail={t('login.email.invalid')}
      >
        <Link color="secondary" to="/password-reset">
          {t('common.passwordReset')}
        </Link>

        <Alert error open={!!errors}>
          {errors?.[0]?.message}
        </Alert>
      </LoginForm>
    </LoginRoot>
  )
}

export default Login
