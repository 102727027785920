import { Suspense, useMemo } from 'react'
import {
  OverlayLoader,
  ProgressIndicator,
  useRouter,
} from '@popety_io/popety-io-lib'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { DashboardRoot } from './Dashboard.style'
import { getDashboardMenuData } from './components/DashboardMenu/DashboardMenu.data'

const pages = getDashboardMenuData()

const Dashboard = () => {
  const { query } = useRouter()

  const content = useMemo(() => {
    return pages.find((p, i) => (!query.tab ? i === 0 : p.key === query.tab))
      ?.page
  }, [query.tab])

  const loading = useSelector((state: any) => {
    return state.dashboard.loading
  })

  return (
    <DashboardRoot>
      <Suspense fallback={<ProgressIndicator circular />}>
        <Box width="100%">{content}</Box>
        <OverlayLoader loading={loading} />
      </Suspense>
    </DashboardRoot>
  )
}

export default Dashboard
