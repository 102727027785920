import { lazy } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import SearchIcon from '@mui/icons-material/Search'
import PeopleIcon from '@mui/icons-material/People'
import ApartmentIcon from '@mui/icons-material/Apartment'
import AssignmentIcon from '@mui/icons-material/Assignment'
import DashboardIcon from '@mui/icons-material/Dashboard'
import HomeIcon from '@mui/icons-material/Home'

const Users = lazy(() => import('../../Users'))
const Other = lazy(() => import('../../Other'))
const Contract = lazy(() => import('../../Contract'))
const Export = lazy(() => import('../../Export'))
const Companies = lazy(() => import('../../Companies'))
const Search = lazy(() => import('../../Search'))
const Home = lazy(() => import('../../Home'))

const getDashboardMenuData = (t = (k: string) => k) => [
  {
    icon: <HomeIcon />,
    key: 'home',
    text: 'Home',
    page: <Home />,
  },
  {
    icon: <PeopleIcon />,
    key: 'users',
    text: t('common.companies.Users'),
    page: <Users />,
  },
  {
    icon: <ApartmentIcon />,
    key: 'companies',
    text: t('common.companies.Companies'),
    page: <Companies />,
  },
  {
    icon: <SearchIcon />,
    key: 'search',
    text: t('common.search'),
    page: <Search />,
  },
  {
    icon: <DownloadIcon />,
    key: 'export',
    text: t('common.export'),
    page: <Export />,
  },
  {
    icon: <AssignmentIcon />,
    key: 'contract',
    text: t('common.companies.Contract'),
    page: <Contract />,
  },
  {
    icon: <DashboardIcon />,
    key: 'other',
    text: t('common.other'),
    page: <Other />,
  },
]

export { getDashboardMenuData }
