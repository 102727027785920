/* eslint-disable max-lines */
import { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  Switch,
  Grid,
} from '@mui/material'
import {
  Snackbar,
  Modal,
  useAsync,
  useLocalFileUpload,
  useI18n,
  useRouter,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import {
  addCompany,
  updateCompany,
  accountManagersSelector,
  getAccountManagers,
  accountManagersByIdSelector,
} from '../../../redux'
import CompaniesService from '../../../redux/companies/companiesService'
import { useThunk } from '../../../../../hooks'
// import SubscriptionTypeForm from '../../../Contract/components/SubscriptionTypeForm'
// import FrequencyForm from '../../../Contract/components/FrequencyForm'
// import StartDateForm from '../../../Contract/components/StartDateForm'
// import AdditionnalContractsInfo from '../../../components/AdditionnalContractsInfoForm'
import UploadLogo from '../../../components/UploadLogo'
import CompanyInfosForm from '../../../components/CompanyInfosForm'
import MulltipleOptionsInput from '../../../Users/components/FormAddUser/MulltipleOptionsInput'
import validateCompanyForm from './FormCompany.validation'

const currentDate = new Date().toISOString()

type FormCompanyProps = {
  company?: Record<string, any>
  onClose: () => void
  [k: string]: any
}

const FormCompany = ({ company, onClose, ...other }: FormCompanyProps) => {
  const { t } = useI18n()
  const { updateQuery } = useRouter()
  const { data, loading, ...thunk } = useThunk()
  const [errors, setErrors] = useState<Record<string, any>>({})

  const dispatch = useDispatch<any>()

  const accountManagersData = useSelector(accountManagersSelector)
  const accountManagersById = useSelector(accountManagersByIdSelector)

  const [preview, setPreview] = useState(company?.logo)
  const [file, selectFile] = useLocalFileUpload()
  const async = useAsync()

  const [values, setValues] = useState<Record<string, any>>({
    ...company,
    postalCode: company?.postal_code,
    type: company?.type_name,
    duration: company?.initial_duration || 0,
    discount: company?.discount_price || 0,
    additionnalUser: company?.additional_user_nb || 0,
    typeId: company?.type_id,
    country: company?.country || 'CH',
    start_date: company?.start_date || currentDate,
    companyTagsCanBeEdited:
      company?.companyTagsCanBeEdited !== undefined
        ? company?.companyTagsCanBeEdited
        : true,
    twoFa: company?.two_fa || false,
    template_email_address: company?.template_email_address,
  })

  const [selectedAccountManager, setSelectedAccountManager] = useState<any>([])
  const [accountManagers, setAccountManagers] = useState<any>([])
  const getNewAccountManager = (newValue: any) => {
    if (newValue.length > 0) {
      if (newValue.length > 1) {
        return [newValue[newValue.length - 1]]
      }

      return [newValue[0]]
    }

    return []
  }

  const onSelectAccountManager = (event: any, newValue: any) => {
    const newAccountManager = getNewAccountManager(newValue)

    setSelectedAccountManager(newAccountManager)
    const newData = {
      ...values,
      account_manager: newAccountManager[0].value,
    }

    setValues(newData)
  }

  useEffect(() => {
    if (data?.data?.id) {
      updateQuery({ companyId: data?.data?.id })
    }
  }, [data])

  useEffect(() => {
    if (!(accountManagersData?.length > 0)) dispatch(getAccountManagers())
  }, [])

  useEffect(() => {
    if (accountManagersData && accountManagersData?.length > 0) {
      const accountManagersFilterd = accountManagersData?.map(
        (accountManager: any) => {
          return {
            value: accountManager?.id,
            label: `${accountManager?.first_name} ${accountManager?.last_name}`,
          }
        },
      )

      setAccountManagers(accountManagersFilterd)
    }
  }, [accountManagersData])

  const handleUpload = () => {
    selectFile?.({
      accept: 'image/png, image/jpeg, image/webp',
      multiple: false,
    })
  }

  useEffect(() => {
    if (!file) return

    const url = URL.createObjectURL(file as any)

    setPreview(url)

    if (company?.id) {
      async.dispatch(() =>
        CompaniesService.updateCompanyLogo({ id: company?.id, file } as any),
      )
    }

    return () => URL.revokeObjectURL(url)
  }, [file])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = { ...values, [e.target.name]: e.target.value }

    setValues(newValue)
    setErrors({})
  }

  const handleTagsPermissionChange = () => {
    setValues({
      ...values,
      companyTagsCanBeEdited: !values.companyTagsCanBeEdited,
    })
  }

  const handle2faChange = () => {
    setValues({
      ...values,
      twoFa: !values.twoFa,
    })
  }

  const handleSubmit = () => {
    const body = {
      ...values,
      postal_code: values?.postalCode,
      logo: file?.name || async.data || values.logo,
      two_fa: values.twoFa,
      template_email_address: values?.template_email_address,
    } as Record<string, any>

    if (body.country) {
      body.country =
        body.country['name.fr'] || body.country.code || body.country
    }

    const err = validateCompanyForm(body, company)

    if (Object.keys(err).length) {
      return setErrors({ ...errors, ...err })
    }

    if (body.id) {
      thunk.dispatch(() => updateCompany(body))
    } else {
      thunk.dispatch(() => addCompany({ body, file } as any))
    }

    onClose()
  }

  useEffect(() => {
    if (data?.data?.id) {
      updateQuery({
        companyId: data?.data?.id,
        tab: 'contract',
        openContract: true,
      })
    }
  }, [data])

  const title =
    other.title ||
    (company?.id ? t('common.updateCompany') : t('common.addCompany'))

  useEffect(() => {
    if (!other.open) {
      setValues({
        ...company,
        postalCode: company?.postal_code,
        type: company?.type_name,
        duration: company?.initial_duration || 0,
        discount: company?.discount_price || 0,
        additionnalUser: company?.additional_user_nb || 0,
        typeId: company?.type_id,
        country: company?.country || 'CH',
        companyTagsCanBeEdited:
          company?.companyTagsCanBeEdited !== undefined
            ? company?.companyTagsCanBeEdited
            : true,
        template_email_address: company?.template_email_address,
      })
    }

    if (company?.account_manager) {
      const accountManager = accountManagersById[company?.account_manager]

      setSelectedAccountManager([
        {
          value: accountManager?.id,
          label: `${accountManager?.first_name} ${accountManager?.last_name}`,
        },
      ])
    }
  }, [!other.open, company])

  return (
    <>
      <Modal
        maxWidth="sm"
        title={title}
        open={other.open}
        onClose={onClose}
        okText={t('common.save')}
        cancelText={t('common.cancel')}
        onOk={handleSubmit}
        onCancel={onClose}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3} pt={3}>
          <CompanyInfosForm
            values={values}
            onChange={handleChange}
            errors={errors}
          />
          <Grid item xs={12} sm={12}>
            <MulltipleOptionsInput
              onSelectOption={onSelectAccountManager}
              options={accountManagers}
              selectedOptions={selectedAccountManager}
              title="Account Manager"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">
                {t('common.allow_edit_tags')}
              </FormLabel>
              <FormGroup>
                <Switch
                  checked={values.companyTagsCanBeEdited}
                  onChange={handleTagsPermissionChange}
                  name="editeTags"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">
                {t('common.2fa_required')}
              </FormLabel>
              <FormGroup>
                <Switch
                  checked={values.twoFa}
                  onChange={handle2faChange}
                  name="edite2faStatus"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <UploadLogo preview={preview} handleUpload={handleUpload} />
        </Box>
      </Modal>
      <Snackbar open={!loading && !!data}>
        {company?.id
          ? t('common.company.Updated')
          : t('common.company.Created')}
      </Snackbar>
      <Snackbar open={!loading && !!thunk.errors} error={!!thunk.errors}>
        {thunk.errors?.[0]?.message || t('common.error.500')}
      </Snackbar>
    </>
  )
}

export default FormCompany
