import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import faosService from './faosService'

const initialState = {
  unclassifiedFaos: [],
  lastCheckDate: '',
}

export const getUnclassifiedFaos = createAsyncThunk(
  'faos/getUnclassifiedFaos',
  faosService.getUnclassifiedFaos,
)

export const putUnclassifiedFaos = createAsyncThunk(
  'faos/putUnclassifiedFaos',
  faosService.putUnclassifiedFaos,
)

const unclassifiedFaos = createSlice({
  name: 'unclassifiedFaos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder.addCase(getUnclassifiedFaos.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        state.unclassifiedFaos = data.data
        state.lastCheckDate = data.lastCheck
      }
    })
    builder.addCase(putUnclassifiedFaos.fulfilled, (state, action) => {
      const { arg } = action.meta

      state.unclassifiedFaos = []
      state.lastCheckDate = arg.currentDate
    })
  },
})

// Action creators are generated for each case reducer function
export const { forgetAuthUser, changeApp, updateLocalUser, logout } =
  unclassifiedFaos.actions

export const unclassifiedFaosReducer = {
  unclassifiedFaos: unclassifiedFaos.reducer,
}
