import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { countriesSelector, getCountries } from '../../../redux'

type CountriesSearchProps = {
  value?: Record<string, any>
  onChange: (v: any) => void
  [k: string]: any
}

const CountriesSearch = ({
  value,
  onChange,
  ...other
}: CountriesSearchProps) => {
  const countries = useSelector(countriesSelector)

  const country = value?.code
    ? value
    : countries.find(
        (c: any) =>
          c['name.fr']?.toLowerCase() === value?.toLowerCase?.() ||
          c['name.en']?.toLowerCase() === value?.toLowerCase?.() ||
          c.code === value,
      )

  const dispatch = useDispatch<any>()

  const handleChange = (e: any, newValue: any) => {
    onChange(newValue)
  }

  useEffect(() => {
    if (!countries?.length) dispatch(getCountries())
  }, [])

  return (
    <Autocomplete
      disableClearable
      options={countries}
      value={country || ''}
      onChange={handleChange}
      isOptionEqualToValue={(option: any, value: any) =>
        option.code === value || option.code === value?.code
      }
      getOptionLabel={(option) =>
        option['name.fr'] ?? option['name.en'] ?? option ?? ''
      }
      disablePortal
      id="countries-search"
      size="small"
      fullWidth
      renderInput={(params) => (
        <TextField {...params} {...other} label="Country" />
      )}
    />
  )
}

export default CountriesSearch
