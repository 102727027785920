/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback } from 'react'
import {
  Modal,
  ProgressIndicator,
  useRouter,
  useI18n,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { ModalProps } from '@popety_io/popety-io-lib/dist/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllCompanies,
  companiesSelector,
  loadingCompaniesSelector,
  usersSearchSelector,
  getUsersSearch,
  usersSearchLoadingSelector,
} from '../../redux'

import FormCompany from '../../Summary/components/FormCompany'
import { CompaniesSearchRoot, ParcelBox } from './CompaniesSearch.style'

let timeoutId: any

export const debouncedDispatch = (actionCreator: any, delay = 100) => {
  return () => {
    return (dispatch: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        dispatch(actionCreator)
      }, delay)
    }
  }
}

const CompaniesSearch = ({ open, onClose, showCloseIcon }: ModalProps) => {
  const [value, setValue] = useState<any>('')
  const [valueUser, setValueUser] = useState<any>('')
  const [openForm, setOpenForm] = useState(false)
  const { query, updateQuery } = useRouter()
  const dispatch: any = useDispatch()
  const companies = useSelector(companiesSelector)
  const loading = useSelector(loadingCompaniesSelector)
  const users = useSelector(usersSearchSelector)
  const loadingUser = useSelector(usersSearchLoadingSelector)
  const { page, size = 25 } = query
  const pageNb = page || 0
  const offset = pageNb * size

  const { t } = useI18n()

  const filters = { limit: size || 0, offset, typeSearch: valueUser }

  useEffect(() => {
    dispatch(getAllCompanies() as any)
  }, [])

  useEffect(() => {
    fetchOnce('listUsers', filters, () => dispatch(getUsersSearch(filters)))
  }, [])

  const handleChange = (
    e: React.ChangeEvent,
    newValue: { id: number | string },
  ) => {
    updateQuery({ companyId: newValue?.id || '' })
  }

  const handleOk = () => {
    setOpenForm(!openForm)
  }

  const handleInputChange = (e: React.ChangeEvent, newInputValue: string) => {
    setValue(newInputValue)
  }

  const handleChangeUser = (e: React.ChangeEvent, newValue: any) => {
    setValueUser(newValue?.name)
    updateQuery({
      companyId: newValue?.company_id || '',
      tab: 'users',
      value: newValue?.name || '',
      userId: newValue?.id || '',
    })
  }

  const fetchUsers = useCallback(
    (newInputValue: any) => {
      const debouncedAction = debouncedDispatch(
        getUsersSearch({ ...filters, typeSearch: newInputValue }) as any,
        2000,
      ) as any

      dispatch(debouncedAction())
    },
    [filters, dispatch],
  )

  const handleInputChangeUser = (
    e: React.ChangeEvent,
    newInputValue: string,
  ) => {
    setValueUser(newInputValue)
    fetchUsers(newInputValue)
  }

  useEffect(() => {
    if (query.companyId) {
      setOpenForm(false)
    }
  }, [query.companyId])

  return (
    <Modal
      top={50}
      open={open}
      keepMounted
      title={t('common.search.companiesSearch')}
      showCloseIcon={showCloseIcon}
      // naked
      blur
      disableGutters
      maxWidth="sm"
      onClose={onClose}
      onOk={handleOk}
      okText="+ Ajouter compagnie"
      sx={{ zIndex: '3 !important', '.MuiDialog-paper': { mt: '-15vh' } }}
    >
      {(loading || loadingUser) && <ProgressIndicator />}

      {!loading && (
        <CompaniesSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
          <ParcelBox>
            <Autocomplete
              id="companies-selector"
              value={value}
              options={companies}
              freeSolo
              disableListWrap
              fullWidth
              onInputChange={handleInputChange as any}
              onChange={handleChange as any}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              getOptionLabel={(option) => option?.name || option}
              isOptionEqualToValue={(option, val) =>
                option?.id === (val?.id || val)
              }
              filterSelectedOptions
              selectOnFocus
              autoSelect
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={`${t('common.search.companiesSearch')}...`}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
              // renderOption={}
            />
          </ParcelBox>
        </CompaniesSearchRoot>
      )}
      <FormCompany onClose={handleOk} open={openForm} />

      {!loading && (
        <CompaniesSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
          <ParcelBox>
            <Autocomplete
              id="users-selector"
              value={valueUser}
              options={users}
              freeSolo
              disableListWrap
              fullWidth
              onInputChange={handleInputChangeUser as any}
              onChange={handleChangeUser as any}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              getOptionLabel={(option) => option?.name || option}
              isOptionEqualToValue={(option, val) =>
                option?.id === (val?.id || val)
              }
              filterSelectedOptions
              selectOnFocus
              autoSelect
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={`${t('common.search.userSearch')}...`}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </ParcelBox>
        </CompaniesSearchRoot>
      )}
    </Modal>
  )
}

export default CompaniesSearch
