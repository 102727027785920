import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const CompaniesHeader = lazy(
  () => import('../../pages/Companies/components/CompaniesHeader'),
)

const SettingsHeader = lazy(
  () => import('../../pages/Settings/components/SettingsHeader'),
)

const LayoutHeaderLeft = () => (
  <Suspense>
    <Routes>
      <Route path="/companies" element={<CompaniesHeader />} />
      <Route path="/setting" element={<SettingsHeader />} />
    </Routes>
  </Suspense>
)

export default memo(LayoutHeaderLeft)
