import { ApiClient } from '../../../../services'
// TODO: Fix this issue
// eslint-disable-next-line import/no-cycle

const client = new ApiClient()

type activeOrInactivateProps = {
  id: string | number
  active: boolean | string
  companyId: string
}

type getAllUsersByCompanyProps = {
  companyId: string
  limit: string | number
  offset: string | number
  typeSearch?: string
  userId?: string | number
}

const getAllUsersByCompany = async ({
  companyId,
  limit = 25,
  offset = 0,
  typeSearch = '',
  userId = '',
}: getAllUsersByCompanyProps) => {
  return client.post(
    `/company/${companyId}/${limit || 25}/${offset || 0}/users/analytics`,
    { typeSearch, userId },
  )
}

const activeOrInactivate = async ({
  id,
  active,
  companyId,
}: activeOrInactivateProps) => {
  const response = active
    ? await client.put(`/company/users/${id}/${companyId}/inactivate`)
    : await client.put(`/company/users/${id}/${companyId}/activate`)

  if (response.data) {
    response.data = { id, status: active ? 'inactive' : 'active' }
  }

  return response
}

type UserProps = {
  input: any
  companyId: string | number
}

const addUser = async ({ input, companyId }: UserProps) => {
  return client.post(`/company/users/${companyId}/add`, input)
}

const updateUser = async ({ input, companyId }: UserProps) =>
  client.put(`/company/users/${input.id}/${companyId}/update`, input)

const deleteUser = async ({ userId, companyId }: any) =>
  client.delete(`/company/users/${userId}/${companyId}/delete`)

const getAccountManagers = async () => {
  return client.get(`/users/role/7`)
}

const resendWelcomeEmail = async ({ userId, companyId }: any) =>
  client.put(`/company/users/${userId}/${companyId}/resendWelcome`)

const usersServices = {
  getAllUsersByCompany,
  activeOrInactivate,
  addUser,
  updateUser,
  deleteUser,
  getAccountManagers,
  resendWelcomeEmail,
}

export default usersServices
