import { lazy } from 'react'

// LOGIN
const Login = lazy(() => import('../pages/Login'))

// PASSWORD RESET
const PasswordReset = lazy(() => import('../pages/PasswordReset'))

// COMPANIES
const Companies = lazy(() => import('../pages/Companies'))

// PGA
const Pga = lazy(() => import('../pages/Pga'))

// PGA FUTURE
const PgaFuture = lazy(() => import('../pages/PgaFuture'))

// FAOS
const Faos = lazy(() => import('../pages/Faos'))

// Settings
const Settings = lazy(() => import('../pages/Settings'))

// ERROR
const ErrorPage = lazy(() => import('../pages/ErrorPage'))

// DASHBOARD
const Dashboard = lazy(() => import('../pages/Dashboard'))

/**
 * navigations
 */
const navigations = [
  { path: '/', page: <Dashboard />, private: true },
  { path: '/login', page: <Login /> },
  { path: '/password-reset', page: <PasswordReset /> },
  { path: '/companies', page: <Companies />, private: true },
  { path: '/pga', page: <Pga />, pga: true },
  { path: '/pga_future', page: <PgaFuture />, pga: true },
  { path: '/faos', page: <Faos />, private: true },
  { path: '/setting', page: <Settings />, private: true },
  { path: '*', page: <ErrorPage status="404" /> },
]

export { navigations }
