import { ApiClient } from '../../../../services'
// TODO: Fix this issue
// eslint-disable-next-line import/no-cycle

const client = new ApiClient()

type LogoOfficeProps = {
  file: File | Blob
  id: string | number
}

type addCompanyProps = {
  file: File | Blob
  body: string[]
}

type getAllUsersProps = {
  limit: string | number
  offset: string | number
  typeSearch?: string
}

const updateCompanyLogo = async ({ id, file }: LogoOfficeProps) => {
  return client.put(`/company/${id}/update-logo`, { file }, { formData: true })
}

const getAllCompanies = async () => client.get(`/company/getAll`)

const getCompanySummary = async (id: number | string) => {
  return client.get(`/company/${id}/analytics`)
}

const addCompany = async ({ body, file }: addCompanyProps) => {
  const data = await client.post(`/company/add`, body)

  if (file && data?.data?.id) {
    client.post(
      `/company/upload/${data?.data?.id}`,
      { file },
      { formData: true },
    )
  }

  return data
}

const updateCompany = async (input: any) =>
  client.put(`/company/update/${input.id}`, input)

const updatePolicy = ({ input, companyId }: any) =>
  client.put(`/company/${companyId}/update-policy`, input)

const getCountries = () => client.get(`/company/countries`)

const updateCompanyColors = async ({ primary, secondary, companyId }: any) => {
  return client.put(`/company/update-colors/${companyId}`, {
    primary,
    secondary,
  })
}

const getUsers = async ({
  limit = 25,
  offset = 0,
  typeSearch = '',
}: getAllUsersProps) => {
  return client.get(
    `/users?offset=${offset}&limit=${limit}&typeSearch=${typeSearch}`,
  )
}

const companiesService = {
  updateCompanyLogo,
  getAllCompanies,
  getCompanySummary,
  addCompany,
  updateCompany,
  updatePolicy,
  getCountries,
  updateCompanyColors,
  getUsers,
}

export default companiesService
