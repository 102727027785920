import { ApiClient } from '../../../../services'

const client = new ApiClient()

type LoginHistoryProps = {
  limit: string | number
  offset: string | number
  typeSearch?: string
  companyId?: string | number
}

const getLoginHistoryByCompany = async ({
  companyId,
  typeSearch = '',
  limit = 25,
  offset = 0,
}: LoginHistoryProps) => {
  return client.post(
    `/back-office/analytics/${limit || 25}/${offset || 0}/login-history`,
    { companyId, typeSearch },
  )
}

const loginHistoryService = {
  getLoginHistoryByCompany,
}

export default loginHistoryService
