export type ValidateCompanyFormOpts = {
  name?: string
  address?: string
  postalCode?: string
  frequency?: any
  city?: string
  start_date?: string
  typeId?: string | number
  id?: string
  template_email_address?: string
}

const regexTemplateEmailAddress = /^@[a-zA-Z0-9._%+-]+\.[a-zA-Z0-9._%+-]+$/

const validateCompanyForm = (opts: ValidateCompanyFormOpts, company: any) => {
  let errors: ValidateCompanyFormOpts = {}
  const {
    address,
    postalCode,
    typeId,
    start_date,
    city,
    name,
    template_email_address,
  } = opts

  if (!name) {
    errors = { ...errors, name: 'Name required' }
  }

  if (!city?.trim()) {
    errors = { ...errors, city: 'City required' }
  }

  if (!address?.trim()) {
    errors = { ...errors, address: 'Address required' }
  }

  if (!postalCode?.trim()) {
    errors = { ...errors, postalCode: 'Postal code required' }
  }

  // if (!template_email_address?.trim()) {
  //   errors = {
  //     ...errors,
  //     template_email_address: 'Template email address required',
  //   }
  // }

  if (
    template_email_address?.trim() &&
    template_email_address &&
    !regexTemplateEmailAddress.test(template_email_address?.trim())
  ) {
    errors = {
      ...errors,
      template_email_address: 'Template email address invalidate',
    }
  }

  if (company && !typeId && !opts?.id) {
    errors = { ...errors, typeId: 'Subscription type required' }
  }

  if (company && !postalCode?.trim()) {
    errors = { ...errors, postalCode: 'Postal code required' }
  }

  if (company && !start_date) {
    errors = { ...errors, start_date: 'Start date required' }
  }

  return errors
}

export default validateCompanyForm
