import { ApiClient } from '../../../../services'
// TODO: Fix this issue
// eslint-disable-next-line import/no-cycle

const client = new ApiClient()

type activeOrInactivateProps = {
  id: string | number
  active: boolean | string
}

const getAllContractsByCompany = async (id: number | string) => {
  return client.get(`/company/${id}/subscription`)
}

const activeOrInactivateOffice = async ({
  id,
  active,
}: activeOrInactivateProps) => {
  const response = active
    ? await client.put(`/company/offices/${id}/inactivate`)
    : await client.put(`/company/offices/${id}/activate`)

  if (response.data) {
    response.data = { id, status: !active }
  }

  return response
}

const addContract = async ({ input, companyId }: any) =>
  client.post(`/company/plan/add/${companyId}`, input)

const updateContract = async ({ input }: any) =>
  client.put(`/company/update-contract/${input.id}`, input)

// const getSubscriptionsType = async () => {
//   return client.get('/company/getSubscriptionsType')
// }

type endSubscriptionProps = {
  id: string | number
  body: any
}

const endSubscription = async ({ body, id }: endSubscriptionProps) => {
  client.put(`/company/subscription/end/${id}`, body)
}

const deleteContract = async (contractId: string | number) => {
  client.delete(`/company/subscription/delete/${contractId}`)
}

const addBilling = async ({ input }: any) =>
  client.post(`/company/billing/add`, input)

const updateBilling = async ({ input }: any) => {
  client.put(`/company/billing/update`, input)
}

const contractsServices = {
  getAllContractsByCompany,
  activeOrInactivateOffice,
  addContract,
  updateContract,
  // getSubscriptionsType,
  endSubscription,
  deleteContract,
  addBilling,
  updateBilling,
}

export default contractsServices
